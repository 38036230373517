<template>
  <v-container fluid class="pa-0">
    <v-navigation-drawer
      v-model="drawer"
      class="white--text elevation-2 pa-2"
      app
      mobile-break-point="960"
      color="white"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title
            class="title"
            style="display: flex; justify-content: space-between; align-items: center"
          >
            <span style="flex-basis: 50%">Sections</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

         <v-list-item
          :to="'/' + this.$i18n.locale + '/admin/requests/shipping-requests'"
          link
        >
          <v-list-item-content>
            <v-list-item-title style="font-size:15px">
              <v-icon size="20" color="indigo" left>fas fa-check-circle</v-icon>

              Approval Requests

              <div
                v-if="this.approvalCount > 0"
                class="indigo counter-badge white--text"
              >
                {{ this.approvalCount }}
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
     
        <v-list-item :to="'/' + this.$i18n.locale + '/admin/changepass'" link>
          <v-list-item-content>
            <v-list-item-title style="font-size:15px">
              <v-icon size="20" color="indigo" left>fas fa-lock</v-icon>

              Change Password
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="logout">
          <v-list-item-content>
            <v-list-item-title style="font-size:15px">
              <v-icon size="20" color="indigo" left>fas fa-sign-out-alt</v-icon>

              Logout
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-toolbar elevation="1" class="grey lighten-5">
      <v-toolbar-side-icon
        class="hidden-md-and-up"
        @click.stop="drawer = !drawer"
        ><v-icon color="black" class="ma-3"
          >fas fa-bars</v-icon
        ></v-toolbar-side-icon
      >

      <v-toolbar-title>Control Panel</v-toolbar-title>

      <v-spacer></v-spacer>
    </v-toolbar>

    <div>
      <router-view></router-view>
    </div>
    
  

    <v-snackbar left :color="color" v-model="snackbar">
      {{ message }}
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import auth from "../../helpers/auth";

axios.defaults.headers.common["Authorization"] = `bearer ${auth.adminToken()}`;

export default {
  name: "AdminPanel",
  data() {
    return {
      drawer: true,
      imagesRequestCount: 0,
      assembleRequestsCount: 0,
      shippingCount: 0,
      supportCount: 0,
      approvalCount: 0,
      bitcoinCount: 0,
      offlineCount: 0,
      alertDialog: false,
      alertType: "",
      alertTextAr: "",
      alertTextFr: "",
      alertTextEn: "",
      message: "",
      snackbar: false,
      setImgReq: true,
      settingDialog: false,
      color: "",
      adminId: 1,
      supportId: 2,
      user: auth.admin(),
    };
  },

  sockets: {
    loadNotifications(data) {
      //console.log(data.notifications)
      // Fired when the socket connects.
      this.imagesRequestCount = data.notifications.imageRequests;
      this.assembleRequestsCount = data.notifications.assembleRequests;
      this.shippingCount = data.notifications.shippingPackages;
      this.supportCount = data.notifications.tickets;
      this.approvalCount = data.notifications.packages;
      this.bitcoinCount = data.notifications.transactions;
      this.offlineCount = data.notifications.offlineTransactions;
    },

    newSupportTicket(data) {
      this.supportCount = data.count;
    },

    newBitcoinPayment(data) {
      this.bitcoinCount = data.count;
    },
    newOfflinePayment(data) {
      this.offlineCount = data.count;
    },

    newImageRequest(data) {
      this.imagesRequestCount = data.count;
    },

    newAssembleRequest(data) {
      this.assembleRequestsCount = data.count;
    },

    newPackageApproveRequest(data) {
      this.approvalCount = data.count;
    },

    newShipping(data) {
      this.shippingCount = data.count;
    },
  },

  mounted() {
    // axios.get('/api/packages/images/count').then((res)=>{
    //     this.imagesRequestCount = res.data.count
    // })

    // axios.get('/api/packages/assemble/count').then((res)=>{
    //     this.assembleRequestsCount = res.data.count
    // })

    // axios.get('/api/packages/shipping/count').then((res)=>{
    //     this.shippingCount = res.data.count
    // })

    // axios.get('/api/admins/support/count').then(res=>{
    //   this.supportCount = res.data.count
    // })

    // axios.get('/api/packages/approval/count').then(res=>{
    //   this.approvalCount = res.data.count
    // })
    axios.get("/api/admins/setting").then((res) => {
      this.setImgReq = res.data.setting.imageRequest;
    });

    // Send the "getNotifications" event to the server.
    this.$socket.emit("adminJoin");
  },
  methods: {
    logout() {
      auth.adminLogout();
      this.$emit("authChange");
      this.$router.push("/en/panel");
    },

    updateSetting() {
      const setting = { imageRequest: this.setImgReq };
      axios
        .post("api/admins/setting", { setting })
        .then((res) => {
          if (!res.data.error) {
            this.message = res.data.message;
            this.snackbar = true;
            this.color = "success";
          } else {
            this.message = "An error happened please try again";
            this.snackbar = true;
            this.color = "error";
          }
        })
        .catch((err) => {
          this.message = "An error happened please try again";
          this.snackbar = true;
          this.color = "error";

          console.log(err);
        });
    },

    handleAlert() {
      const type = this.alertType;
      const textEn = this.alertTextEn;
      const textAr = this.alertTextAr;
      const textFr = this.alertTextFr;

      axios
        .post("api/admins/alert/create", {
          type,
          textEn,
          textAr,
          textFr,
        })
        .then((res) => {
          if (!res.data.error) {
            this.message = res.data.message;
            this.snackbar = true;
            this.color = "success";

            this.alertType = "";
            this.alertTextEn = "";
            this.alertTextAr = "";
            this.alertTextFr = "";
            this.alertDialog = false;
          } else {
            this.message = "An error happened please try again";
            this.snackbar = true;
            this.color = "error";
          }
        })
        .catch((err) => {
          this.message = "An error happened please try again";
          this.snackbar = true;
          this.color = "error";

          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.counter-badge {
  padding: 5px;
  display: inline-block;
  font-size: 14px;
  height: 23px;
  width: 23px;
  text-align: center;
  padding-bottom: 10px !important;
}
</style>
